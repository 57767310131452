export default defineNuxtRouteMiddleware((to, from) => {
    const { user } = useUserSession();
    const { has_tech_catalog } = usePermissions();

    if (["/user/logout", "/landing"].includes(to.path)) {
        return;
    }

    if (null == user.value) {
        return navigateTo("/landing");
    }

    if (["/user/account", "/user/landing"].includes(to.path)) {
        return;
    }

    if (false == has_tech_catalog) {
        return navigateTo("/user/landing");
    }
});
