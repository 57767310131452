import auth0 from "auth0-js";

export default defineNuxtPlugin(async (nuxtApp) => {
    const auth0conf = useRuntimeConfig().public.auth0;
    const webAuth = new auth0.WebAuth({
        clientID: auth0conf.clientId,
        domain: auth0conf.domain,
        redirectUri: useRuntimeConfig().public.auth0_redirecturi,
    });
    return {
        provide: {
            webAuth: webAuth,
        },
    };
});
