import revive_payload_client_TNuwhvzoAq from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_awcrso3cov4et7q2a7hnytc2da/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_xBD3taP7ou from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_awcrso3cov4et7q2a7hnytc2da/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_dc5T1W8mZY from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_awcrso3cov4et7q2a7hnytc2da/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_6UlAN2rBTD from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_awcrso3cov4et7q2a7hnytc2da/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_DRGWiFalOL from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_awcrso3cov4et7q2a7hnytc2da/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_gOlNWklM3q from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_awcrso3cov4et7q2a7hnytc2da/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_xTtyaDjg40 from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_awcrso3cov4et7q2a7hnytc2da/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_siUigEtlYN from "/app/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_awcrso3cov4et7q2a7hnytc2da/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_wu8mo1ReH2 from "/app/node_modules/.pnpm/@storyblok+nuxt@6.2.0/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import plugin_4jb27WKkHM from "/app/node_modules/.pnpm/@nuxt+icon@1.9.0_magicast@0.3.5_rollup@4.28.0_vite@5.4.11_@types+node@22.10.1_sass-embedded@1_te3th6amus3ycxafibmyjvuvlm/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_8pZihMZcrT from "/app/node_modules/.pnpm/nuxt-graphql-client@0.2.41_@parcel+watcher@2.5.0_@types+node@22.10.1_graphql-tag@2.12.6_graph_pwa5a3exujwy4i3uckgig2yxay/node_modules/nuxt-graphql-client/dist/runtime/plugin.js";
import session_client_DqRzRvjpI2 from "/app/node_modules/.pnpm/nuxt-auth-utils@0.5.5_magicast@0.3.5_rollup@4.28.0/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import auth0_ZnwwTmFbx2 from "/app/plugins/auth0.ts";
import translate_56zPzW3R38 from "/app/plugins/translate.ts";
export default [
  revive_payload_client_TNuwhvzoAq,
  unhead_xBD3taP7ou,
  router_dc5T1W8mZY,
  payload_client_6UlAN2rBTD,
  navigation_repaint_client_DRGWiFalOL,
  check_outdated_build_client_gOlNWklM3q,
  chunk_reload_client_xTtyaDjg40,
  components_plugin_KR1HBZs4kY,
  prefetch_client_siUigEtlYN,
  plugin_wu8mo1ReH2,
  plugin_4jb27WKkHM,
  plugin_8pZihMZcrT,
  session_client_DqRzRvjpI2,
  auth0_ZnwwTmFbx2,
  translate_56zPzW3R38
]