const missing: Ref<any[]> = ref([]);

export default defineNuxtPlugin(async () => {
    const { data: translations } = await useAsyncData(
        "translations",
        async () => {
            const oldValue = useNuxtData("translations").data.value;
            if (oldValue) return oldValue;

            const [it, de, en, es, fr, ru, zh] = await Promise.all([
                useFetch(`http://nuxt:3000/translations/IT.json`),
                useFetch(`http://nuxt:3000/translations/DE.json`),
                useFetch(`http://nuxt:3000/translations/EN.json`),
                useFetch(`http://nuxt:3000/translations/ES.json`),
                useFetch(`http://nuxt:3000/translations/FR.json`),
                useFetch(`http://nuxt:3000/translations/RU.json`),
                useFetch(`http://nuxt:3000/translations/ZH.json`),
            ]);

            return {
                it: it.data,
                de: de.data,
                en: en.data,
                es: es.data,
                fr: fr.data,
                ru: ru.data,
                zh: zh.data,
            };
        }
    );

    const locale = useCookie("lang", {
        default: () => "it",
    });

    const t = function (key: string) {
        const realKey = "catalogue." + key;
        if (!translations.value[locale.value]) {
            missing.value.push(key);
            return key;
        }

        if (!translations.value[locale.value][realKey]) {
            missing.value.push(key);
            return key;
        }

        return translations.value[locale.value][realKey];
    };

    return {
        provide: {
            t: t,
        },
    };
});
