<template>
    <!-- You can use $pwa directly in templates! -->
    <div class="UpdateNotification" v-show="$pwa?.needRefresh">
        <span>
            New content available, click on reload button to update.
        </span>

        <button @click="$pwa?.updateServiceWorker()">
            Reload
        </button>
    </div>
</template>
<script setup lang="ts">
// If you want to use it in setup, import from the nuxtApp.
const { $pwa } = useNuxtApp()

onMounted(() => {
    if ($pwa?.offlineReady) {
        console.log('App ready to work offline')
    } else {
        console.warn('App NOT ready to work offline')
    }
})

</script>
