export default function usePermissions() {
    const { user } = useUserSession();

    return {
        has_admin:
            user?.value?.profile?.user_metadata?.crm_data?.has_admin === true,
        has_tech_catalog:
            user?.value?.profile?.user_metadata?.crm_data?.has_tech_catalog ??
            false,
        has_any_prices:
            user?.value?.profile?.user_metadata?.crm_data?.has_prices_cny ||
            user?.value?.profile?.user_metadata?.crm_data?.has_prices_gbp ||
            user?.value?.profile?.user_metadata?.crm_data?.has_prices_rub ||
            user?.value?.profile?.user_metadata?.crm_data?.has_prices_usd ||
            user?.value?.profile?.user_metadata?.crm_data?.has_prices ||
            false,
        has_prices:
            user?.value?.profile?.user_metadata?.crm_data?.has_prices ?? false,
        has_prices_cny:
            user?.value?.profile?.user_metadata?.crm_data?.has_prices_cny ??
            false,
        has_prices_gbp:
            user?.value?.profile?.user_metadata?.crm_data?.has_prices_gbp ??
            false,
        has_prices_rub:
            user?.value?.profile?.user_metadata?.crm_data?.has_prices_rub ??
            false,
        has_prices_usd:
            user?.value?.profile?.user_metadata?.crm_data?.has_prices_usd ??
            false,
        has_public_prices:
            user?.value?.profile?.user_metadata?.crm_data?.has_public_prices ??
            false,
        has_quick_ship:
            user?.value?.profile?.user_metadata?.crm_data?.has_quick_ship ??
            false,
    };
}
