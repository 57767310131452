import { default as i18nN0Fhfsyp08Meta } from "/app/pages/admin/i18n.vue?macro=true";
import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as PIMD8n55GTsk9Meta } from "/app/pages/admin/PIM.vue?macro=true";
import { default as translationspeFoPZxRMqMeta } from "/app/pages/admin/translations.vue?macro=true";
import { default as usersgSsTxNnO5UMeta } from "/app/pages/admin/users.vue?macro=true";
import { default as avvertenzec629nIPa32Meta } from "/app/pages/avvertenze.vue?macro=true";
import { default as comunicazionipMlzqOQUNEMeta } from "/app/pages/comunicazioni.vue?macro=true";
import { default as contact_us2ENKyCzvDXMeta } from "/app/pages/contact_us.vue?macro=true";
import { default as covert8AVB6GFXKMeta } from "/app/pages/cover.vue?macro=true";
import { default as _91alias_93xzgx6k7uRXMeta } from "/app/pages/dashboards/[alias].vue?macro=true";
import { default as index0BjuCgbIktMeta } from "/app/pages/dashboards/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as landingrvSETkGqnoMeta } from "/app/pages/landing.vue?macro=true";
import { default as materassijQHIszQqZ8Meta } from "/app/pages/materassi.vue?macro=true";
import { default as _91id_93kVZKMJ3tbEMeta } from "/app/pages/products/[id].vue?macro=true";
import { default as visitedAiRWIgxylmMeta } from "/app/pages/products/visited.vue?macro=true";
import { default as index5RNpYHq2vQMeta } from "/app/pages/quickship/index.vue?macro=true";
import { default as _91id_93ZmjfJN7HAeMeta } from "/app/pages/quickship/log/[id].vue?macro=true";
import { default as indexQ6cgt4bX4iMeta } from "/app/pages/quickship/log/index.vue?macro=true";
import { default as requests8XFbmbl7OzMeta } from "/app/pages/requests.vue?macro=true";
import { default as set_45lettoh3uil6ACUwMeta } from "/app/pages/set-letto.vue?macro=true";
import { default as accountNLsYTOLGq0Meta } from "/app/pages/user/account.vue?macro=true";
import { default as landing2SiJ1R76QLMeta } from "/app/pages/user/landing.vue?macro=true";
export default [
  {
    name: "admin-i18n",
    path: "/admin/i18n",
    meta: i18nN0Fhfsyp08Meta || {},
    component: () => import("/app/pages/admin/i18n.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexi8nCATZISJMeta || {},
    component: () => import("/app/pages/admin/index.vue")
  },
  {
    name: "admin-PIM",
    path: "/admin/PIM",
    meta: PIMD8n55GTsk9Meta || {},
    component: () => import("/app/pages/admin/PIM.vue")
  },
  {
    name: "admin-translations",
    path: "/admin/translations",
    meta: translationspeFoPZxRMqMeta || {},
    component: () => import("/app/pages/admin/translations.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    meta: usersgSsTxNnO5UMeta || {},
    component: () => import("/app/pages/admin/users.vue")
  },
  {
    name: "avvertenze",
    path: "/avvertenze",
    component: () => import("/app/pages/avvertenze.vue")
  },
  {
    name: "comunicazioni",
    path: "/comunicazioni",
    component: () => import("/app/pages/comunicazioni.vue")
  },
  {
    name: "contact_us",
    path: "/contact_us",
    component: () => import("/app/pages/contact_us.vue")
  },
  {
    name: "cover",
    path: "/cover",
    component: () => import("/app/pages/cover.vue")
  },
  {
    name: "dashboards-alias",
    path: "/dashboards/:alias()",
    component: () => import("/app/pages/dashboards/[alias].vue")
  },
  {
    name: "dashboards",
    path: "/dashboards",
    component: () => import("/app/pages/dashboards/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "landing",
    path: "/landing",
    meta: landingrvSETkGqnoMeta || {},
    component: () => import("/app/pages/landing.vue")
  },
  {
    name: "materassi",
    path: "/materassi",
    component: () => import("/app/pages/materassi.vue")
  },
  {
    name: "products-id",
    path: "/products/:id()",
    component: () => import("/app/pages/products/[id].vue")
  },
  {
    name: "products-visited",
    path: "/products/visited",
    component: () => import("/app/pages/products/visited.vue")
  },
  {
    name: "quickship",
    path: "/quickship",
    meta: index5RNpYHq2vQMeta || {},
    component: () => import("/app/pages/quickship/index.vue")
  },
  {
    name: "quickship-log-id",
    path: "/quickship/log/:id()",
    component: () => import("/app/pages/quickship/log/[id].vue")
  },
  {
    name: "quickship-log",
    path: "/quickship/log",
    component: () => import("/app/pages/quickship/log/index.vue")
  },
  {
    name: "requests",
    path: "/requests",
    component: () => import("/app/pages/requests.vue")
  },
  {
    name: "set-letto",
    path: "/set-letto",
    component: () => import("/app/pages/set-letto.vue")
  },
  {
    name: "user-account",
    path: "/user/account",
    component: () => import("/app/pages/user/account.vue")
  },
  {
    name: "user-landing",
    path: "/user/landing",
    component: () => import("/app/pages/user/landing.vue")
  }
]