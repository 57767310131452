<template>
    <div>
        <!-- <NuxtPwaAssets /> -->
        <NuxtRouteAnnouncer />
        <UpdateNotification />
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>
<script setup>

</script>
